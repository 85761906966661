// HTTP METHODS:
export const GET = 'GET';
export const POST = 'POST';
export const PATCH = 'PATCH';
export const PUT = 'PUT';
export const DELETE = 'DELETE';

// RESPONSE STATUSES
export const FORBIDDEN_STATUS = 403;
export const NO_CONTENT_STATUS = 204;
export const NOT_FOUND_STATUS = 404;

// HEADERS:
export const CONTENT_TYPE = 'Content-type';
export const APPLICATION_JSON = 'application/json';
export const APPLICATION_XLSX =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const APPLICATION_PDF = 'application/pdf';

export const API_BASE_URL: string = `${process.env.REACT_APP_R_AND_D_API_BASE_URL}/api`;

export const RESOURCE_TYPES = {
  BATCHES: 'batches',
  COMPANIES: 'companies',
  CUSTOMERS: 'customers',
  EMAILS: 'emails',
  EMPLOYEES: 'employees',
  FEEDBACKS: 'feedbacks',
  FINAL_BRIEFS: 'final_briefs',
  FORMULAS: 'formulas',
  INCIS: 'incis',
  INGREDIENTS: 'ingredients',
  MANUFACTURERS: 'manufacturers',
  NOTIFICATIONS: 'notifications',
  ORDERS: 'orders',
  PHASES: 'phases',
  PROJECTS: 'projects',
  RAW_MATERIALS: 'raw_materials',
  RTL_CATALOGUE_ENTRIES: 'rtl_catalogue_entries',
  STANDARDS: 'standards',
  SUPPLIERS: 'suppliers',
  DOCUMENTS: 'documents',
  THIRD_PARTY_TESTS: 'third_party_tests',
  WORKSHEETS: 'worksheets',
} as const;

export const API_TYPES = {
  BATCH: 'batch',
  CLASSIFICATION: 'classification',
  COMPANY: 'company',
  EMPLOYEE: 'employee',
  FORMULA: 'formula',
  INCI: 'inci',
  INGREDIENT: 'ingredient',
  PHASE: 'phase',
  PRICE: 'price',
  MANUFACTURER: 'manufacturer',
  RAW_MATERIAL: 'rawMaterial',
  RAW_MATERIAL_INCI: 'rawMaterialInci',
  SAMPLE: 'sample',
  SAMPLE_SUBMISSION: 'sampleSubmission',
  STANDARDS: 'standards',
  SUPPLIER: 'supplier',
  THIRD_PARTY_TESTS: 'thirdPartyTest',
};

// ERROR NAMES:
export const LOGIN_REQUIRED = 'login_required';
