// Libraries
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
// Components
import { Autocomplete, Button, Page, Select, TextField } from 'design-system';
import { ConfirmClearCartModal, RtlFormulaCatalogueTopNav } from '.';
// Utils
import { RtlShoppingCartContext } from 'context';
import { useRenderCartItems } from './render-cart-items.hook';
import { useApi } from 'api';
// Constants
import { STATES } from 'features/brief/internal-brief/sample-recipients.component';
import { ROUTES } from 'features/navigation';

export const RtlFormulaCatalogueShippingDetails = () => {
  const navigate = useNavigate();
  const { searchCompanies, createProjectsForRtlCatalogueOrder } = useApi();
  const { clearCart, numberOfItemsInCart, rtlCart } = useContext(
    RtlShoppingCartContext
  );
  const cartItems = useRenderCartItems();
  const [isClearCartModalOpen, setIsClearCartModalOpen] = React.useState(false);

  type ShippingFormValues = {
    company: Option;
    firstName: string;
    lastName: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zip: string;
  };

  const formik = useFormik<ShippingFormValues>({
    initialValues: {
      company: { label: '', value: '' },
      firstName: '',
      lastName: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zip: '',
    },
    validationSchema: Yup.object({
      company: Yup.object()
        .shape({ label: Yup.string(), value: Yup.string() })
        .required('Please enter a company name'),
      firstName: Yup.string().required('Please enter a first name'),
      lastName: Yup.string().required('Please enter a last name'),
      addressLine1: Yup.string().required('Please enter a valid address'),
      city: Yup.string().required('Please enter a city'),
      state: Yup.string().required('Please select a state'),
      zip: Yup.string().required('Please enter a valid zip code'),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      createProjectsForRtlCatalogueOrder({
        urlParams: 'create_projects_for_rtl_catalogue_order',
        data: {
          project: {
            companyUuid: values.company.value,
            sampleRecipientsAttributes: [
              {
                firstName: values.firstName,
                lastName: values.lastName,
                address_1: values.addressLine1,
                address_2: values.addressLine2,
                city: values.city,
                state: values.state,
                zip: values.zip,
              },
            ],
            rtlCatalogueEntryAttributes: rtlCart.map((cartItem) => {
              return {
                uuid: cartItem.id,
                sample_quantity: cartItem.quantity,
              };
            }),
          },
        },
        handleSuccess: () => {
          const tempCart = [...rtlCart];

          clearCart();

          navigate(ROUTES.RTL_FORMULA_CATALOGUE_ORDER_CONFIRMATION.route, {
            state: {
              cart: tempCart,
              shippingInfo: {
                company: values.company,
                firstName: values.firstName,
                lastName: values.lastName,
                addressLine1: values.addressLine1,
                addressLine2: values.addressLine2,
                city: values.city,
                usState: values.state,
                zip: values.zip,
              },
            },
          });
        },
      });
    },
  });

  // Behavior to redirect user to RTL Catalogue home if tere are no items in cart
  useEffect(() => {
    if (numberOfItemsInCart === 0) {
      navigate(ROUTES.RTL_FORMULA_CATALOGUE.route);
    }
  });

  const handleClearCart = () => {
    setIsClearCartModalOpen(false);
    navigate(ROUTES.RTL_FORMULA_CATALOGUE.route);
  };

  const { errors, handleSubmit, values, getFieldProps, setFieldValue } = formik;

  return (
    <Page>
      <RtlFormulaCatalogueTopNav hideShoppingCart />
      {isClearCartModalOpen && (
        <ConfirmClearCartModal handleClose={handleClearCart} />
      )}
      <div className="col-span-12 flex flex-col sm:flex-row mt-24 gap-6 font-inter text-grey-20 max-w-[1264px] mx-auto">
        <div className="flex flex-col gap-3 w-[62%]">
          <span>Sample Recipients</span>
          <div className="flex flex-col bg-white p-6 gap-6 border border-grey-90 rounded">
            <div>
              <Autocomplete
                clearField={() => setFieldValue('company', '')}
                error={errors.company}
                helperText={
                  values.company
                    ? ''
                    : `If sending to a new client not yet in our database, first add new company to Monday.`
                }
                onChange={(option: Option) => {
                  setFieldValue('company', option);
                }}
                initialValue=""
                placeholder="Search Customer Companies"
                responseParser={(response) =>
                  response?.data.map((result: any) => ({
                    label: result.attributes.name,
                    value: result.id,
                  }))
                }
                search={searchCompanies}
                value={values.company}
              />
            </div>
            {values.company && (
              <>
                <div className="flex gap-9">
                  <TextField
                    label="First Name"
                    error={errors.firstName}
                    {...getFieldProps('firstName')}
                  />
                  <TextField
                    error={errors.lastName}
                    label="Last Name"
                    {...getFieldProps('lastName')}
                  />
                </div>
                <div>
                  <TextField
                    error={errors.addressLine1}
                    label="Address Line 1"
                    width="w-full"
                    {...getFieldProps('addressLine1')}
                  />
                </div>
                <div>
                  <TextField
                    label="Address Line 2"
                    width="w-full"
                    {...getFieldProps('addressLine2')}
                  />
                </div>
                <div className="flex flex-col sm:flex-row gap-9">
                  <div className="w-auto">
                    <TextField
                      error={errors.city}
                      label="City"
                      {...getFieldProps('city')}
                    />
                  </div>
                  <div>
                    <Select
                      error={errors.state}
                      label="State"
                      options={STATES}
                      onChange={(option: Option) => {
                        setFieldValue('state', option.value);
                      }}
                      value={values.state}
                    />
                  </div>
                  <div className="shrink">
                    <TextField
                      error={errors.zip}
                      label="Zip"
                      {...getFieldProps('zip')}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          {values.company && (
            <>
              <Button
                text="Submit"
                type="primary"
                width="w-full"
                onClick={handleSubmit}
                size="large"
              />
              <span className="text-xs text-grey-50">
                When you click “Submit,” a request will be sent to R&D in Monday
                to assemble and ship your chosen samples to the above recipient.
                The customer will receive an email notification when the samples
                have shipped.
              </span>
            </>
          )}
        </div>
        <div className="flex flex-col w-[38%] gap-3 pb-10">
          <span>Samples Cart</span>
          <div className="bg-grey-95 p-3 flex flex-col gap-3">{cartItems}</div>
          <div className="flex justify-between items-center font-mono text-xs uppercase">
            <span className="">{numberOfItemsInCart} items total</span>
            <span
              onClick={() => setIsClearCartModalOpen(true)}
              className="text-grey-50 underline cursor-pointer"
            >
              Clear cart
            </span>
          </div>
        </div>
      </div>
    </Page>
  );
};
