// Libraries
import React, { useContext, useEffect, useState } from 'react';
import { Button, Chip, Divider, Typography } from 'design-system';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
// Utils
import { ExtendedFormulaAttributes } from '../types';
import { useApi } from 'api';
import { useApi as useApiV1 } from 'hooks';
import { UserContext } from 'context';
// Assets
import lotionSqueezeBottle from 'img/lotion-squeeze-bottle.png';
// Constants
import * as Constants from 'features/constants';

interface RtlSelectionProps {
  rtlFormulaUuid: string;
  userHasSelectedToProceedWithRtl: boolean;
  setUserHasSelectedToProceedWithRtl: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

// Temp hardcoded values for report generation
const formatType = 'pdf';
const reportTypes = {
  cosmetics: {
    type: 'USCosmetics',
    inputs: {
      params: 'roundBy',
      value: '0',
    },
  },
  drugs: {
    type: 'USDrugs',
    inputs: {
      params: 'displayLessThanOnePercentLine',
      value: false,
    },
  },
};

type ReportInputs = {
  params: string;
  value: any;
};

const cardOption = {
  yes: 'yes',
  no: 'no',
};

export const RtlSelection: React.FC<RtlSelectionProps> = ({
  rtlFormulaUuid,
  userHasSelectedToProceedWithRtl,
  setUserHasSelectedToProceedWithRtl,
}) => {
  const request = useApiV1();
  const { getFormula } = useApi();
  const { userSession } = useContext(UserContext)!;
  const { customerMetadata } = userSession || {};

  const [formula, setFormula] = useState<Maybe<ExtendedFormulaAttributes>>(
    undefined
  );
  const [isReportGenerating, setIsReportGenerating] = useState<boolean>(false);

  useEffect(() => {
    if (rtlFormulaUuid) {
      getFormula({
        urlParams: rtlFormulaUuid,
        handleSuccess: (data) => {
          const { id, attributes, ingredients } = data;
          setFormula({
            id,
            ...attributes,
            ingredients: [...ingredients],
          });
        },
      });
    }
  }, [getFormula, rtlFormulaUuid]);

  const hasActiveIngredients = formula?.ingredients.some(
    (ingredient) => ingredient.attributes.active
  );

  const renderIngredientsList = () => {
    return hasActiveIngredients
      ? renderActiveIngredientsList()
      : renderNonActiveIngredientsList();
  };

  const renderNonActiveIngredientsList = () => {
    return formula?.ingredients.map((ingredient, index) => (
      <span key={ingredient.id} className="font-inter text-sm text-grey-20">
        {ingredient.attributes.rawMaterialName}
        {+formula.ingredients.length - 1 === index ? '' : ', '}
      </span>
    ));
  };

  const renderActiveIngredientsList = () => {
    const activeIngredients = formula?.ingredients.filter(
      (ingredient) => ingredient.attributes.active
    );
    const inactiveIngredients = formula?.ingredients
      .filter((ingredient) => !ingredient.attributes.active)
      .flatMap((ingredient) =>
        ingredient.attributes.rawMaterialUsIncis.map((inci) => inci.name)
      )
      .filter((name) => name !== null && name !== undefined)
      .sort();

    // individual ingredients may have incis that are the same, so we need to remove duplicates
    const uniqueInactiveIngredients = Array.from(new Set(inactiveIngredients));

    return (
      <div className="flex flex-col gap-4">
        <table>
          <thead>
            <tr>
              <th className="text-left font-inter">Active Ingredients</th>
              <th></th>
              <th className="text-left">Purpose</th>
            </tr>
          </thead>
          <tbody>
            {activeIngredients!.map((ingredient: any, index) => (
              <tr key={index}>
                <td>{ingredient.attributes.rawMaterialName}</td>
                <td>{ingredient.attributes.amount}%</td>
                {/* this may not be correct since there can potentially be more than one purpose */}
                <td>{ingredient.attributes.usApiDrugPurposes[0]}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div>
          <Typography variant="h6" font="inter" weight="semibold">
            Inactive Ingredients
          </Typography>
          <div>
            {uniqueInactiveIngredients.map((ingredient, index) => (
              <span key={index}>
                {ingredient}
                {+uniqueInactiveIngredients.length - 1 === index ? '' : ', '}
              </span>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const generateReport = async () => {
    const report = hasActiveIngredients
      ? reportTypes.drugs
      : reportTypes.cosmetics;

    try {
      setIsReportGenerating(true);
      const blob = await request({
        resource: `/api/v1/formulas/${rtlFormulaUuid}.${formatType}?reportType=${
          report.type
        }${buildUrlParameters(report.inputs)}&companyName=${
          customerMetadata?.companyName
        }`,
        options: {
          settings: Constants.GET,
        },
      });

      if (blob.size) {
        const blobData = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobData;
        link.setAttribute('target', '_blank');
        link.download = `${formula?.name} ${
          report.type
        } ${new Date().toISOString().replace(/T.*/, '')}.${formatType}`;
        link.click();

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(blobData);
        }, 100);
        // showSnackbar('Report generated successfully', 'success');
      } else {
        throw new Error('The report failed to generate.');
      }
    } catch (error) {
      // showSnackbar((error as Error).message, 'error');
      Rollbar.error(error);
    } finally {
      setIsReportGenerating(false);
    }
  };

  const buildUrlParameters = (inputs: ReportInputs) => {
    return Object.values(inputs || {})
      .map((v) => {
        return v.value && v.params ? `&${v.params}=${v.value}` : '';
      })
      .join('');
  };

  const handleCardSelection = (option: string) => {
    setUserHasSelectedToProceedWithRtl(option === cardOption.yes);
  };

  return (
    <div className="flex flex-col mt-14 gap-y-12" data-testid={rtlFormulaUuid}>
      {/* HEADER section */}
      <div className="flex flex-col gap-y-6 items-start">
        <Typography variant="h2" font="agipo" color="primary">
          Congrats!
        </Typography>
        <Typography variant="h4" font="inter" color="primary">
          Your selections match one of our Ready-to-Launch formulas:
        </Typography>
      </div>

      {/* FORMULA section */}
      <div
        className={`flex flex-row p-4 bg-grey-99 border rounded border-grey-90 items-start gap-x-6 w-2/3`}
      >
        <div>
          <img src={lotionSqueezeBottle} width="293px" height="222px" alt="" />
        </div>
        {formula ? (
          <div className="flex flex-col gap-y-4 w-1/2">
            <Typography variant="h3" font="inter" weight="semibold">
              {formula?.name}
            </Typography>
            <Typography variant="h6" font="mono" weight="semibold">
              INGREDIENTS LIST:
            </Typography>
            <Typography font="inter" variant="h5" color="secondary">
              {renderIngredientsList()}
            </Typography>
            <div className="flex flex-row gap-4">
              <Button
                leadingIcon={<ArrowDownTrayIcon />}
                type="primary"
                text="Download IL"
                action={generateReport}
                disabled={isReportGenerating}
                width="max-w-full w-full"
              />
              {/* TODO activate this button when rtl catalogue site is ready */}
              {/* <Button type="secondary" text="More details..." /> */}
            </div>
          </div>
        ) : (
          <Typography variant="h6" font="mono" weight="semibold">
            Loading ingredients...
          </Typography>
        )}
      </div>

      <Divider direction="horizontal" className="w-2/3" />

      {/* CARDS section */}
      <div className="flex flex-col gap-y-6 w-2/3">
        <Typography variant="h4" font="inter" color="primary">
          Would you like to move forward with this RTL Formula?
        </Typography>

        <div className="flex flex-row gap-x-6">
          <div
            className={`flex flex-col gap-y-4 border rounded p-4 cursor-pointer
                ${
                  userHasSelectedToProceedWithRtl
                    ? 'border-royal-50 bg-blue-95'
                    : 'border-grey-90'
                }
              `}
            onClick={() => handleCardSelection(cardOption.yes)}
          >
            <Typography variant="h2" font="inter" weight="semibold">
              Yes
            </Typography>
            <Typography variant="h5" font="inter">
              By selecting this option, I approve the above Base IL.
            </Typography>
            <Typography variant="h5" font="inter">
              I understand that I will be able to request adjustments to
              ingredients and fragrance.
            </Typography>
            <Chip
              additionalClasses="w-fit-content"
              bgColor="bg-peach-95"
              borderColor="border-peach-80"
              label="Development Cost: $0"
              noHover
              textColor="text-peach-40"
              value="rtl"
            />
            <Chip
              additionalClasses="w-fit-content"
              bgColor="bg-peach-95"
              borderColor="border-peach-80"
              label="Estimated Timeline: 6 - 12 months"
              noHover
              textColor="text-peach-40"
              value="rtl"
            />
          </div>

          <div
            className={`flex flex-col gap-y-4 border rounded p-4 cursor-pointer
                ${
                  !userHasSelectedToProceedWithRtl
                    ? 'border-royal-50 bg-blue-95'
                    : 'border-grey-90'
                }
              `}
            onClick={() => handleCardSelection(cardOption.no)}
          >
            <Typography variant="h2" font="inter" weight="semibold">
              No
            </Typography>
            <Typography variant="h5" font="inter">
              I’d like to move forward with my selections to create a Custom
              Development product.
            </Typography>
            <Typography variant="h5" font="inter">
              I understand this will result in longer timelines and higher
              costs.
            </Typography>
            <Chip
              additionalClasses="w-fit-content"
              bgColor="bg-blue-95"
              borderColor="border-blue-80"
              label="Development Cost: $5000 - $10,000"
              noHover
              textColor="text-blue-40"
              value="customDevelopment"
            />
            <Chip
              additionalClasses="w-fit-content"
              bgColor="bg-blue-95"
              borderColor="border-blue-80"
              label="Estimated Timeline: 12 - 18 months"
              noHover
              textColor="text-blue-40"
              value="customDevelopment"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
