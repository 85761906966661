// Libraries
import React from 'react';
// Components
import { AdditionalDetails } from '../../form-modules';

export const ProductVision = () => {
  window.history.pushState(null, '', '/custom-brief/#additional-details');

  return (
    <div className="flex flex-col gap-12 pt-[52px] mb-9 sm:mb-0">
      <div className="flex flex-col sm:grid sm:grid-cols-9 gap-12">
        <div className="col-span-6">
          <AdditionalDetails />
        </div>
      </div>
    </div>
  );
};
