// Libraries
import React from 'react';
import { Divider, Typography } from 'design-system';
// Components
import { SampleRecipients } from 'features/brief/internal-brief/sample-recipients.component';
import { SampleQuantity } from 'features/brief/form-modules';

interface FormulaSamplesProps {
  projectIsRtl?: Maybe<boolean>;
}

export const FormulaSamples: React.FC<FormulaSamplesProps> = ({
  projectIsRtl,
}) => {
  return (
    <div className="flex flex-col pt-[52px]">
      <div className="flex flex-col sm:grid sm:grid-cols-9 gap-12">
        <div
          id="sample-recipients"
          className="flex flex-col col-span-6 gap-y-9"
        >
          <div>
            <Typography variant="h2" font="agipo">
              Sample Recipient
            </Typography>
            <Typography variant="h4" color="secondary">
              To whom would you like your samples sent?
            </Typography>
          </div>
          <SampleRecipients />
          {projectIsRtl && (
            <div>
              <Divider className="mb-9" />
              <SampleQuantity />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
