// Libraries
import React, { useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
// Components
import { Button } from 'design-system';
// Utils
import { useRenderCartItems } from './render-cart-items.hook';
import { MobileContext } from 'context';
// Contants
import { ROUTES } from 'features/navigation';

export const RtlFormulaCatalogueOrderConfirmation = () => {
  const { state } = useLocation();
  const cartItems = useRenderCartItems(true);
  const navigate = useNavigate();
  const { isMobile } = useContext(MobileContext);

  const shippingInfo = state?.shippingInfo || {};

  return (
    <div className="relative font-inter text-grey-20">
      <div className="bg-grey-30 sm:pl-32 sm:pr-16 pt-[140px] px-6 sm:px-0">
        <div className="sm:w-[50%]">
          <span className="text-grey-90 text-[48px] font-agipo leading-[130%]">
            {isMobile
              ? 'Your order has been submitted.'
              : 'Your sample request has been sent to the lab.'}
          </span>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-24 sm:pl-32 sm:pr-16 px-6 sm:px-0 mt-4">
        <div className="flex flex-col gap-9 sm:w-[60%]">
          <span>
            R&D will now collect your requested samples and send them to the
            customer. The customer will receive a confirmation email when their
            samples have shipped. You can track the status of the samples in{' '}
            <Link
              className="text-royal-50 underline"
              to="https://prime-matter-labs-team.monday.com/boards/5284439546"
            >
              Monday.
            </Link>
          </span>
          <div className="flex flex-col">
            <span className="font-[500]">Sample Recipient</span>
            <span>{shippingInfo?.companyName}</span>
            <span>
              {shippingInfo?.firstName} {shippingInfo?.lastName}
            </span>
            <span>{shippingInfo?.addressLine1}</span>
            <span>{shippingInfo?.addressLine2}</span>
            <span>
              {shippingInfo?.city}, {shippingInfo?.usState}, {shippingInfo?.zip}
            </span>
          </div>
          <Button
            onClick={() => navigate(ROUTES.RTL_FORMULA_CATALOGUE.route)}
            type="soft"
            text="Return to Catalogue"
          />
        </div>
        <div className="relative sm:-top-40 flex flex-col gap-3 sm:w-[40%]">
          <span className="sm:text-grey-90">Your Samples</span>
          <div className="bg-grey-90 p-3 flex flex-col gap-3 rounded">
            {cartItems}
          </div>
        </div>
      </div>
    </div>
  );
};
