// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { Typography, TextArea } from 'design-system';
// Utils
import { InternalBriefFormValues } from '../internal-brief/form.config';
// Constants
import { BRIEF_FORM_KEYS } from '../brief.constants';

export const AdditionalDetails: React.FC = () => {
  const { getFieldProps } = useFormikContext<InternalBriefFormValues>();

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col">
        <Typography variant="h2" font="agipo">
          Product Vision
        </Typography>
        <Typography variant="h4" font="inter" color="disabled">
          Share a brief overview about your product’s function, purpose,
          directions for use, objective, target demographic, etc.
        </Typography>
      </div>
      <div className="flex flex-col gap-3">
        <TextArea
          {...getFieldProps(BRIEF_FORM_KEYS.PRODUCT_DESCRIPTION)}
          placeholder="Product Vision"
        />
      </div>
    </div>
  );
};
