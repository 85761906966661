import React from 'react';

interface SquareIconProps {
  backgroundColor: string;
  icon: React.ReactElement;
}

export const SquareIcon: React.FC<SquareIconProps> = ({
  backgroundColor,
  icon,
}) => {
  return <div className={`w-6 h-6 p-1 rounded ${backgroundColor}`}>{icon}</div>;
};
