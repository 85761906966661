import React from 'react';
import { CheckIcon } from '@heroicons/react/16/solid';

interface ISwitch {
  isChecked: boolean;
  handleChange: () => void;
  size?: 'sm' | 'md' | 'lg';
}

export const Switch: React.FC<ISwitch> = ({
  isChecked,
  handleChange,
  size = 'sm',
}) => {
  const width =
    size === 'sm' ? 'w-[36px]' : size === 'md' ? 'w-[45px]' : 'w-[52px]';
  const height =
    size === 'sm' ? 'h-[20px]' : size === 'md' ? 'h-[24px]' : 'h-[28px]';
  const ellipseSize =
    size === 'sm' ? 'w-4 h-4' : size === 'md' ? 'w-5 h-5' : 'w-6 h-6';
  const translateAmount =
    size === 'sm'
      ? 'translate-x-[18px]'
      : size === 'md'
      ? 'translate-x-[23px]'
      : 'translate-x-[26px]';

  return (
    <label className="flex cursor-pointer select-none items-center">
      <div className="relative">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => handleChange()}
          className="sr-only"
        />
        <div
          className={`block h-5 ${height} ${width} rounded-full transition duration-500 ${
            isChecked ? 'bg-royal-50' : 'bg-grey-90'
          }`}
        ></div>
        <div
          className={`absolute top-[2px] ${ellipseSize} rounded-full bg-white transition duration-500 ${
            isChecked ? translateAmount : 'translate-x-[2px]'
          }`}
        >
          <CheckIcon
            className={` text-royal-50 transition duration-500 ${
              isChecked ? 'opacity-100' : 'opacity-0'
            }`}
          />
        </div>
      </div>
    </label>
  );
};

export default Switch;
