// Libraries
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import {
  BarsArrowDownIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ClockIcon,
  EyeDropperIcon,
  HandRaisedIcon,
  MagnifyingGlassIcon,
  PlusCircleIcon,
  PlusIcon,
  SparklesIcon,
  SunIcon,
  WrenchIcon,
} from '@heroicons/react/16/solid';
import { Bars4Icon, Squares2X2Icon } from '@heroicons/react/24/outline';
// Components
import {
  Button,
  Chip,
  Menu,
  Page,
  Pagination,
  Select,
  Spinner,
  TextField,
} from 'design-system';
import { RtlFormulaCatalogueTopNav, ShoppingCart } from './';
// Utils
import { USDollar } from 'features/utils';
import { MobileContext, RtlShoppingCartContext, useAlert } from 'context';
import { useApi } from 'api';
import { useIsTouchDevice } from 'hooks';
// Constants
import { RTL_CATALOGUE_IMAGES, RLT_CATALOGUE_STATUSES } from './constants';
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';
import { PRODUCT_CATEGORIES } from 'features/brief/brief.constants';
import { primaryComponentOptions } from 'features/brief/brief.constants';

const sortDropdownOptions = [
  {
    label: 'Alphabetical',
    value: 'display_name',
    leadingIcon: <BarsArrowDownIcon className="h-5 w-5 text-grey-50" />,
  },
  {
    label: 'Newest',
    value: 'created_at',
    leadingIcon: <ClockIcon className="h-5 w-5 text-grey-50" />,
  },
  {
    label: 'Last Modified',
    value: 'updated_at',
    leadingIcon: <WrenchIcon className="h-5 w-5 text-grey-50" />,
  },
  {
    label: 'Relevance',
    value: '_score',
    leadingIcon: <BarsArrowDownIcon className="h-5 w-5 text-grey-50" />,
  },
];

const productCategories = [
  {
    label: PRODUCT_CATEGORIES.SUN_CARE,
    value: PRODUCT_CATEGORIES.SUN_CARE,
    leadingIcon: <SunIcon className="h-5 w-5 text-peach-50" />,
  },
  {
    label: PRODUCT_CATEGORIES.SKIN_CARE,
    value: PRODUCT_CATEGORIES.SKIN_CARE,
    leadingIcon: <EyeDropperIcon className="h-5 w-5 text-royal-50" />,
  },
  {
    label: PRODUCT_CATEGORIES.HAIR_CARE,
    value: PRODUCT_CATEGORIES.HAIR_CARE,
    leadingIcon: <SparklesIcon className="h-5 w-5 text-teal-50" />,
  },
  {
    label: PRODUCT_CATEGORIES.BODY_CARE,
    value: PRODUCT_CATEGORIES.BODY_CARE,
    leadingIcon: <HandRaisedIcon className="h-5 w-5 text-yellow-50" />,
  },
];

const formulaTableHeaders = [
  {
    label: 'MOQ',
    value: 'moq',
  },
  {
    label: 'Format',
    value: 'format',
  },
  {
    label: 'Component',
    value: 'component',
  },
  {
    label: 'Sample Size',
    value: 'size',
  },
  {
    label: 'CPU',
    value: 'cpu',
  },
];

export const RtlFormulaCatalogue = () => {
  const [primarySortOption, setPrimarySortOption] = useState(
    sortDropdownOptions[3]
  );
  const [secondarySortOption, setSecondarySortOption] = useState<string | null>(
    null
  );
  const [rtlCatalogueEntries, setRtlCatalogueEntries] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [
    userInitiatedSearchCompleted,
    setUserInitiatedSearchCompleted,
  ] = useState<boolean>(false);

  const [selectedFilterChips, setSelectedFilterChips] = useState<string[]>([]);
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  const [
    showProductCategoryDropDown,
    setShowProductCategoryDropDown,
  ] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);

  const navigate = useNavigate();
  const { isMobile } = useContext(MobileContext);
  const isTouchDevice = useIsTouchDevice();
  const { addItem } = useContext(RtlShoppingCartContext);
  const { showAlertWithTimeout } = useAlert();
  const { searchRtlCatalogueEntries } = useApi();

  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    _.debounce((query) => {
      setIsLoading(true);

      searchRtlCatalogueEntries({
        query: encodeURIComponent(query),
        urlParams: `&page=${currentPage}&perPage=15&filters=${selectedFilterChips.join(
          ','
        )}&sort=${secondarySortOption || primarySortOption.value}`,
        handleSuccess: (data) => {
          setRtlCatalogueEntries(data.results);
          setTotalResults(data.total?.value || 0);
        },
        handleFinally: () => {
          setIsLoading(false);
          if (query) setUserInitiatedSearchCompleted(true);
        },
      });
    }, 500),
    [
      currentPage,
      primarySortOption,
      secondarySortOption,
      searchRtlCatalogueEntries,
      selectedFilterChips,
    ]
  );

  useEffect(() => {
    setCurrentPage(1);
    setSecondarySortOption(null);
  }, [searchQuery]);

  useEffect(() => {
    debouncedSearch(searchQuery);
  }, [searchQuery, debouncedSearch]);

  // Function to handle the click event on the product category dropdown
  const handleProductCategoryClick = (option: Option) => {
    navigate('/rtl-formula-catalogue/new', {
      state: {
        passedProductCategory: option.value,
      },
    });
  };

  // Function that handles the rendering of the catalogue entry image in the grid view
  const renderGridViewCatalogueEntryImage = (entry: any) => {
    if (!entry.image_id) {
      return (
        <div className="flex items-center justify-center h-[191px] w-full bg-grey-70 rounded">
          <span>No Image Selected</span>
        </div>
      );
    }
    return (
      <div className="flex items-center justify-center relative cursor-pointer">
        {entry.status !== 'draft' && !isTouchDevice && (
          <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100">
            <Button
              leadingIcon={<PlusCircleIcon className="w-6 h-6" />}
              onClick={(e: Event) => {
                e.stopPropagation();

                showAlertWithTimeout({
                  content: <span>Item added to cart</span>,
                  severity: 'success',
                });

                const cartItem = {
                  id: entry.uuid,
                  formulaUuid: entry.formula.uuid,
                  imageId: entry.image_id,
                  quantity: 1,
                  price: entry.price,
                  displayName: entry.display_name,
                  structure: entry.product.structure,
                  primaryContainer: entry.product.primaryComponent,
                };
                addItem(cartItem);
              }}
              text="Add to Cart"
              variant="primary"
            />
          </div>
        )}
        <img
          src={
            RTL_CATALOGUE_IMAGES.find((image) => image.id === entry.image_id)
              ?.src || 'http://www.google.com'
          }
          alt="entry"
          className="h-[191px] w-full object-cover rounded"
        />
      </div>
    );
  };

  // Function that handles the rendering of the catalogue entry image in the list view
  const renderListViewCatalogueEntryImage = (entry: any) => {
    if (!entry.image_id) {
      return (
        <div className="flex items-center justify-center h-6 w-6 bg-grey-70 rounded" />
      );
    }
    return (
      <img
        src={
          RTL_CATALOGUE_IMAGES.find((image) => image.id === entry.image_id)
            ?.src || 'http://www.google.com'
        }
        alt="entry"
        className="h-6 w-6 object-cover sm:w-[191px] rounded"
      />
    );
  };

  // Function that handles the rendering of table headers in the list view
  const renderTableHeaders = () => {
    return formulaTableHeaders.map((header) => (
      <div
        key={header.value}
        onClick={() => {
          setSecondarySortOption(header.value);
        }}
        className="flex items-center gap-1 cursor-pointer"
      >
        <span>{header.label}</span>
        {secondarySortOption === header.value ? (
          <ChevronUpIcon className="h-4 w-4 text-royal-50" />
        ) : (
          <ChevronDownIcon className="h-4 w-4 text-royal-50" />
        )}
      </div>
    ));
  };

  const renderRowValues = (entry: any) => {
    return (
      <>
        <span>
          {entry?._source?.minimum_order_quantity &&
            new Intl.NumberFormat().format(
              entry._source.minimum_order_quantity
            )}
        </span>
        <span>{entry?._source.product.structure}</span>
        <span>
          {entry?._source.product.primary_component
            ? primaryComponentOptions.find(
                (option) =>
                  option.value === entry?._source.product.primary_component
              )?.label
            : ''}
        </span>
        <span>
          {entry?._source.product?.fill_size &&
            entry?._source.product?.unit &&
            `${entry?._source.product.fill_size} ${entry?._source.product.unit}`}
        </span>
        <span className="text-grey-20">
          {USDollar.format(entry?._source.price || 0)}
        </span>
      </>
    );
  };

  // Function that handles the rendering of catalogue entry table rows in the list view
  const renderCatalogueEntryRows = () => {
    if (!rtlCatalogueEntries?.length) return;

    return rtlCatalogueEntries.map((entry: any, index: number) => {
      return isMobile ? (
        <div
          key={`${entry._source.uuid}-${index}`}
          className="flex flex-col py-3 gap-6"
        >
          <div className="flex items-center gap-4">
            {renderListViewCatalogueEntryImage(entry._source)}
            <span className="text-black font-semibold w-full whitespace-nowrap overflow-hidden text-ellipsis">
              {entry._source.display_name}
            </span>
          </div>
          <div className="flex flex-col gap-2 text-grey-50">
            {renderRowValues(entry)}
          </div>
          <Button
            disabled={entry._source.status === RLT_CATALOGUE_STATUSES.DRAFT}
            text="Add to Cart"
            type="primary"
            size="small"
            leadingIcon={<PlusIcon className="h-4 w-4" />}
          />
        </div>
      ) : (
        <div
          key={`${entry._source.uuid}-${index}`}
          onClick={() =>
            navigate(
              ROUTES.RTL_FORMULA_CATALOGUE_ENTRY.route.replace(
                UUID_SHOW_ROUTE_STRING,
                entry._source.uuid
              )
            )
          }
          className="cursor-pointer grid items-center grid-cols-rtlFormulaCatalogueListViewTableHeader py-3 gap-x-[56px] border-b border-grey-90 text-grey-50"
        >
          {renderListViewCatalogueEntryImage(entry._source)}
          <div className="flex items-center gap-1 cursor-pointer -ml-10">
            {entry._source.status === RLT_CATALOGUE_STATUSES.DRAFT && (
              <Chip
                additionalClasses="mr-[4px]"
                bgColor="bg-red-95"
                borderColor="border-red-80"
                label="Draft"
                size="small"
                textColor="text-red-40"
                value="Draft"
              />
            )}
            <span className=" text-black font-semibold whitespace-nowrap overflow-hidden text-ellipsis">
              {entry?._source.display_name || 'TBD'}
            </span>
          </div>
          {renderRowValues(entry)}
          <div>
            <Button
              disabled={entry._source.status === RLT_CATALOGUE_STATUSES.DRAFT}
              text="Add to Cart"
              type="primary"
              size="small"
              leadingIcon={<PlusIcon className="h-4 w-4" />}
            />
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <Page>
        <RtlFormulaCatalogueTopNav />
        <ShoppingCart />
        <div className="max-w-[1440px] sm:col-span-12 col-span-2 mx-auto">
          <div className="flex flex-col sm:flex-row sm:justify-between pt-52">
            <div className="sm:w-1/2 w-full flex flex-col">
              <span className="font-agipo text-[48px] leading-[56px] font-bold">
                RTL Formula Library
              </span>
              <span className="font-inter text-grey-50">
                This is PML’s library of off-the-shelf formulas. These formulas
                have a faster production time and lower costs to our clients
                because they’ve been pre-tested. Clients can make minor
                adjustments to fragrance and ingredients. Browse formulas, then
                add them to your cart to send samples to clients.
              </span>
            </div>
            <div className="self-start relative mt-16 sm:mt-0 w-full sm:w-auto z-30">
              <Button
                leadingIcon={<PlusIcon />}
                onClick={() => setShowProductCategoryDropDown(true)}
                size="large"
                text="New RTL Formula"
                type="primary"
                width="w-full"
              />
              {showProductCategoryDropDown && (
                <Menu
                  options={productCategories}
                  onChange={(option: Option) =>
                    handleProductCategoryClick(option)
                  }
                  onClose={() => setShowProductCategoryDropDown(false)}
                />
              )}
            </div>
          </div>
          <div className="sticky top-[72px] col-span-12 py-16 bg-white z-20">
            <div className="bg-white flex flex-col gap-6 sm:gap-0 sm:flex-row justify-between items-center">
              <div className="flex flex-col gap-2 w-full sm:w-1/2">
                <TextField
                  placeholder="Search"
                  trailingIcon={
                    <MagnifyingGlassIcon className="text-grey-50 h-5 w-5" />
                  }
                  value={searchQuery}
                  onChange={(e: any) => {
                    setSearchQuery(e.target.value);
                    setUserInitiatedSearchCompleted(false);
                  }}
                  height="h-11"
                />
                {userInitiatedSearchCompleted &&
                  (rtlCatalogueEntries.length > 0 ? (
                    <span className="font-inter text-grey-20">
                      Showing results for{' '}
                      <span className="font-bold">{searchQuery}</span>
                    </span>
                  ) : (
                    <span className="font-inter text-grey-20">
                      No results for{' '}
                      <span className="font-bold">{searchQuery}.</span>
                      <span> Make sure your search excludes typos.</span>
                    </span>
                  ))}
              </div>
              <div className="w-full sm:w-auto flex items-center gap-6">
                <div className="flex gap-3 text-grey-50">
                  <Squares2X2Icon
                    className={`h-6 w-6 cursor-pointer ${
                      viewMode === 'grid' && 'text-royal-50'
                    }`}
                    onClick={() => setViewMode('grid')}
                  />
                  <Bars4Icon
                    className={`h-6 w-6 cursor-pointer ${
                      viewMode === 'list' && 'text-royal-50'
                    }`}
                    onClick={() => setViewMode('list')}
                  />
                </div>
                <Select
                  leadingIcon={primarySortOption.leadingIcon}
                  options={sortDropdownOptions}
                  value={primarySortOption}
                  onChange={(option: any) => {
                    setPrimarySortOption(option);
                    setCurrentPage(1);
                    setSecondarySortOption(null);
                  }}
                  width="w-full"
                />
              </div>
            </div>
          </div>
          <div className="pb-6 flex sticky top-[312px] sm:top-[244px] col-span-12 justify-center z-10 bg-white">
            <div className="flex gap-6 sm:gap-0 flex-col sm:flex-row w-full justify-between items-center">
              <div className="flex flex-wrap gap-4 justify-center flex-grow">
                {productCategories.map((productCategory) => {
                  return (
                    <Chip
                      key={productCategory.value}
                      bgColor="bg-grey-95"
                      closeIconColor="text-royal-80"
                      label={productCategory.label}
                      onClick={() => {
                        setSelectedFilterChips((prev) => {
                          if (prev.includes(productCategory.value)) {
                            return [...prev];
                          }
                          return [...prev, productCategory.value];
                        });
                      }}
                      onCloseIconClick={(event) => {
                        event.stopPropagation();
                        setSelectedFilterChips((prev) => {
                          return prev.filter(
                            (value) => value !== productCategory.value
                          );
                        });
                      }}
                      textColor="text-grey-40"
                      selected={selectedFilterChips.includes(
                        productCategory.value
                      )}
                      selectedBgColor="bg-royal-95"
                      selectedTextColor="text-royal-40"
                      value={productCategory.value}
                    />
                  );
                })}
              </div>
              <span className="mr-auto sm:ml-auto">
                {totalResults} formulas
              </span>
            </div>
          </div>
          {isLoading ? (
            <div className="flex justify-center items-center w-full h-full">
              <Spinner />
            </div>
          ) : viewMode === 'grid' ? (
            <div className="flex justify-center sm:justify-start gap-x-6 col-span-2 sm:col-span-12 flex-wrap gap-y-9 mb-6 -mx-[8px]">
              {rtlCatalogueEntries?.length &&
                rtlCatalogueEntries.map((entry: any, index: number) => {
                  return (
                    <div
                      onClick={() =>
                        navigate(
                          ROUTES.RTL_FORMULA_CATALOGUE_ENTRY.route.replace(
                            UUID_SHOW_ROUTE_STRING,
                            entry._source.uuid
                          )
                        )
                      }
                      key={index}
                      className="flex w-full justify-start sm:flex-grow sm:basis-[calc(20%-1rem)] sm:min-w-[191px] flex-col font-inter text-sm cursor-pointer rounded hover:bg-blue-95 p-[8px]"
                    >
                      {renderGridViewCatalogueEntryImage(entry._source)}
                      <div className="flex justify-between items-center my-[6px]">
                        <div className="flex w-2/3">
                          {entry._source.status ===
                            RLT_CATALOGUE_STATUSES.DRAFT && (
                            <Chip
                              additionalClasses="mr-[4px]"
                              bgColor="bg-red-95"
                              borderColor="border-red-80"
                              label="Draft"
                              size="small"
                              textColor="text-red-40"
                              value="Draft"
                            />
                          )}
                          <span className="font-semibold whitespace-nowrap overflow-hidden overflow-ellipsis">
                            {entry._source.display_name || 'TBD'}
                          </span>
                        </div>
                        <span className="">
                          {USDollar.format(entry._source.price || 0)}
                        </span>
                      </div>
                      <span className="text-grey-50">
                        {entry._source.format}
                      </span>
                      <span className="text-grey-50">
                        {entry._source.component}
                      </span>
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className="max-w-[1440px] col-span-12 font-inter text-sm">
              <div className="hidden sm:grid grid-cols-rtlFormulaCatalogueListViewTableHeader gap-x-[56px] py-3 border-b border-grey-90">
                <div id="imageColumnPlaceholder" className="py-3"></div>
                <div
                  onClick={() => {
                    setSecondarySortOption(sortDropdownOptions[0].value);
                  }}
                  className="flex items-center gap-1 cursor-pointer -ml-10"
                >
                  <span>Formula Name</span>
                  {secondarySortOption === sortDropdownOptions[0].value ? (
                    <ChevronUpIcon className="h-4 w-4 text-royal-50" />
                  ) : (
                    <ChevronDownIcon className="h-4 w-4 text-royal-50" />
                  )}
                </div>
                {renderTableHeaders()}
              </div>
              <div id="addToCartButtonPlaceholder"></div>
              {renderCatalogueEntryRows()}
            </div>
          )}
          <div className="max-w-[1440px] col-span-12">
            <Pagination
              currentPage={currentPage}
              entriesPerPage={15}
              setCurrentPage={setCurrentPage}
              totalResults={totalResults}
            />
          </div>
        </div>
      </Page>
    </>
  );
};
