//Libraries
import React, { useEffect, useState, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import * as amplitude from '@amplitude/analytics-browser';
//Components
import CustomerApp from 'features/customer-app.component';
import EmployeeApp from 'features/employee-app.component';
import LeftNav from './navigation/left-nav.component';
import { ErrorBoundary } from 'features/error';
import {
  UserContext,
  MobileContextProvider,
  SnackbarContextProvider,
} from 'context';
import { Loader, Footer } from 'features/ui';
import { Navigation } from 'features/navigation';
import { Alert } from 'design-system';
// Constants
import { ROLES } from 'features/rbac';
import { LeftNavContextProvider, useAlert } from 'context';
import { RtlShoppingCartContextProvider } from 'context/rtl-shopping-cart-context';

const App: React.FC = () => {
  const { user, isLoading, isAuthenticated } = useAuth0();
  const { alert, hideAlert } = useAlert();

  const { roles, setRoles } = useContext(UserContext)!;

  const [areRolesLoading, setAreRolesLoading] = useState<boolean>(true);

  useEffect(() => {
    if (user) {
      setRoles(user['http://www.primematterlabs.com/roles']);
      setAreRolesLoading(false);
    }
  }, [user, setRoles]);

  useEffect(() => {
    if (!user) return;

    process.env.REACT_APP_AMPLITUDE_API_KEY &&
      amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY, {
        userId: user.email,
      });
  }, [user]);

  useEffect(() => {
    if (roles.length <= 0) return;

    const identifyEvent = new amplitude.Identify();
    identifyEvent.setOnce(
      'user_role',
      roles.includes(ROLES.EMPLOYEE) ? 'employee' : 'customer'
    );
    amplitude.identify(identifyEvent);
  });

  if (isLoading || (isAuthenticated && areRolesLoading)) {
    return <Loader />;
  }

  const isEmployee = roles && roles.includes(ROLES.EMPLOYEE);
  return (
    <div className="relative min-h-screen">
      <div data-testid={isEmployee ? 'employee-app' : 'customer-app'}>
        <MobileContextProvider>
          <SnackbarContextProvider>
            {alert && (
              <Alert
                content={alert.content}
                headline={alert.headline}
                severity={alert.severity}
                onClose={hideAlert}
              />
            )}
            <ErrorBoundary>
              {isEmployee ? (
                <EmployeeApp>
                  <LeftNavContextProvider>
                    <RtlShoppingCartContextProvider>
                      <Navigation isEmployee={isEmployee} />
                      <LeftNav />
                    </RtlShoppingCartContextProvider>
                  </LeftNavContextProvider>
                </EmployeeApp>
              ) : (
                <>
                  <CustomerApp>
                    <Navigation />
                  </CustomerApp>
                  <div className="bg-white absolute bottom-0 w-full">
                    <Footer />
                  </div>
                </>
              )}
            </ErrorBoundary>
          </SnackbarContextProvider>
        </MobileContextProvider>
      </div>
    </div>
  );
};

export default App;
