import React from 'react';
import { Link } from 'react-router-dom';

export type Breadcrumb = {
  label: string;
  link?: string;
};

interface BreadcrumbMenuProps {
  breadcrumbs: Breadcrumb[];
}

export const BreadcrumbMenu: React.FC<BreadcrumbMenuProps> = ({
  breadcrumbs,
}) => {
  return (
    <div className="flex flex-wrap gap-2 text-sm text-grey-50 font-inter">
      {breadcrumbs.map((breadcrumb, index) => (
        <React.Fragment key={index}>
          {breadcrumb.link ? (
            <Link className="cursor-pointer underline" to={breadcrumb.link}>
              {breadcrumb.label}
            </Link>
          ) : (
            <span>{breadcrumb.label}</span>
          )}
          {index < breadcrumbs.length - 1 && <span>{'>'}</span>}
        </React.Fragment>
      ))}
    </div>
  );
};
