// Libraries
import React, { useEffect, useState } from 'react';
import { Button, Typography } from 'design-system';
// Components
// Utils
import { ExtendedFormulaAttributes } from '../types';
// Assets
import RtlConfirmationCream from 'img/rtl-confirmation-cream.png';
import { useApi } from 'api';
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';
import { useNavigate } from 'react-router-dom';
// Constants

interface RtlConfirmationProps {
  rtlFormulaUuid: string | null;
  projectUuid: string | null;
}

export const RtlConfirmation: React.FC<RtlConfirmationProps> = ({
  rtlFormulaUuid,
  projectUuid,
}) => {
  // Hooks
  const navigate = useNavigate();
  const { getFormula } = useApi();
  // State
  const [formula, setFormula] = useState<Maybe<ExtendedFormulaAttributes>>(
    undefined
  );

  useEffect(() => {
    if (rtlFormulaUuid) {
      getFormula({
        urlParams: rtlFormulaUuid,
        handleSuccess: (data) => {
          const { id, attributes, ingredients } = data;
          setFormula({
            id,
            ...attributes,
            ingredients: [...ingredients],
          });
        },
      });
    }
  }, [getFormula, rtlFormulaUuid]);

  return (
    <div className="flex flex-row justify-center">
      {formula ? (
        <div className="flex flex-col gap-y-9 justify-center ">
          <Typography variant="h1" font="agipo">
            Thank you for requesting your{' '}
            <span className="text-peach-60">{formula?.name}</span> sample.
          </Typography>
          <Typography variant="h3" font="inter">
            You will receive an email confirmation when it ships.
          </Typography>
          <Button
            text="View your Project Tracker"
            type="primary"
            action={() =>
              navigate(
                ROUTES.SHOW_CUSTOMER_PROJECT_V2.route.replace(
                  UUID_SHOW_ROUTE_STRING,
                  projectUuid!
                )
              )
            }
          />
        </div>
      ) : (
        <Typography variant="h4">Loading formula...</Typography>
      )}
      {formula && (
        <div className="h-full">
          <img
            src={RtlConfirmationCream}
            alt="rtl-confirmation-cream"
            className="h-auto"
          />
        </div>
      )}
    </div>
  );
};
