// Libraries
import React, { useContext } from 'react';
import {
  ChevronDownIcon,
  PlusIcon,
  QueueListIcon,
} from '@heroicons/react/16/solid';
// Components
import {
  Autocomplete,
  Button,
  Chip,
  Pagination,
  Select,
  SquareIcon,
} from 'design-system';
// Utils
import { useApi } from 'api';
import { LeftNavContext, MobileContext } from 'context';

const CHIPS = [
  {
    label: 'Retailer Standards',
    value: 'retailer-standards',
  },
  {
    label: 'Client No-Nos',
    value: 'client-no-nos',
  },
  {
    label: 'INCI Families',
    value: 'inci-families',
  },
  {
    label: 'Claim Groups',
    value: 'claim-groups',
  },
];

const SORT_OPTIONS = [
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Type',
    value: 'type',
  },
  {
    label: 'Added By',
    value: 'addedBy',
  },
  {
    label: 'Last Modified',
    value: 'lastModified',
  },
];

const tableHeaders = ['', 'Name', 'Type', 'Added By', 'Last Modified'];

export const RmInciGroupsUtility = () => {
  const dummyRows = [
    {
      icon: (
        <SquareIcon
          backgroundColor="bg-peach-90"
          icon={<QueueListIcon className="w-4 h-4 text-peach-40" />}
        />
      ),
      name: 'Ulta Clean',
      type: 'Retailer Standard',
      addedBy: 'John Doe',
      lastModified: '04/19/21',
    },
    {
      icon: (
        <SquareIcon
          backgroundColor="bg-peach-90"
          icon={<QueueListIcon className="w-4 h-4 text-peach-40" />}
        />
      ),
      name: 'Sephora Clean',
      type: 'Claim Group',
      addedBy: 'Aleta H. Starcher',
      lastModified: '08/21/15',
    },
    {
      icon: (
        <SquareIcon
          backgroundColor="bg-peach-90"
          icon={<QueueListIcon className="w-4 h-4 text-peach-40" />}
        />
      ),
      name: 'Antioxidant Compounds',
      type: 'INCI Family',
      addedBy: 'Roy X. Hinde',
      lastModified: '01/19/14',
    },
  ];
  // Temp stand in for search
  const { searchFormulas } = useApi();
  const { isLeftNavOpen } = useContext(LeftNavContext);
  const { isMobile } = useContext(MobileContext);

  const renderDesktopTable = () => {
    return (
      <>
        <div className="grid grid-cols-rmInciGroupsUtilityTable py-3 gap-12">
          {tableHeaders.map((header, idx) => (
            <div
              key={header}
              className={`flex ${
                idx === tableHeaders.length - 1 ? 'ml-auto' : ''
              } ${idx === 1 ? '-ml-10' : ''}`}
            >
              <div className="flex cursor-pointer items-center gap-1">
                <span className="text-sm">{header}</span>
                {idx !== 0 && (
                  <ChevronDownIcon className="h-4 w-4 text-royal-50" />
                )}
              </div>
            </div>
          ))}
        </div>
        {dummyRows.map((row) => (
          <div
            key={row.name}
            className="grid grid-cols-rmInciGroupsUtilityTable items-center gap-12 py-4 text-grey-50 border-t border-grey-90"
          >
            <div>{row.icon}</div>
            <div className="text-royal-50 -ml-10 cursor-pointer">
              {row.name}
            </div>
            <div className="text-grey-20">{row.type}</div>
            <div>{row.addedBy}</div>
            <div className="ml-auto">{row.lastModified}</div>
          </div>
        ))}
      </>
    );
  };

  const renderMobileTable = () => {
    return (
      <div className="flex flex-col gap-6">
        {dummyRows.map((row) => (
          <div
            key={row.name}
            className="flex flex-col gap-3 border-b border-grey-90 py-4"
          >
            <div className="flex items-center gap-2">
              {row.icon}
              <span className="text-royal-50 cursor-pointer">{row.name}</span>
            </div>
            <div className="flex flex-col gap-3">
              <span>{row.type}</span>
              <span className="text-grey-50">{row.addedBy}</span>
              <span className="text-grey-50">{row.lastModified}</span>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      {isMobile && (
        <div className="sticky top-0 z-50 w-full p-6 pl-12 mx-auto bg-white font-agipo">
          RM & INCI Groups
        </div>
      )}
      <div className="bg-[url(img/rm-inci-groups-bg.png)] bg-cover bg-no-repeat min-h-[372px] sm:min-h-[416px] text-grey-20">
        <div
          className={`max-w-[1440px] pt-[204px] sm:pt-[252px] flex flex-col font-inter pr-6 sm:pr-16 ${
            isLeftNavOpen && !isMobile ? 'pl-[303px]' : 'sm:pl-32 pl-6'
          }`}
        >
          <div className="flex flex-col sm:flex-row sm:justify-between gap-16 pb-[30px]">
            <div className="sm:w-1/2 w-full flex flex-col">
              <span className="font-agipo text-[48px] leading-[56px] font-bold">
                RM & INCI Groups
              </span>
              <span className="font-inter text-grey-50">
                Create and update groups of RMs and INCIs. These groups can
                represent Retailer Standards, Client No-Nos, and other families
                of RMs and/or INCIs for reference in project details,
                formulation, etc.
              </span>
            </div>
            <Button
              leadingIcon={<PlusIcon className="h-6 w-6" />}
              size="large"
              text="New Group"
            />
          </div>
        </div>
      </div>
      <div
        className={`max-w-[1440px] flex flex-col gap-8 pr-6 sm:pr-16 mt-8 ${
          isLeftNavOpen && !isMobile ? 'pl-[303px]' : 'sm:pl-32 pl-6'
        }`}
      >
        <div className="flex flex-col gap-6 sm:w-1/2">
          <Autocomplete
            responseParser={(response) =>
              response?.results.map((result: any) => ({
                label: result._source.external_id,
                value: result._source.uuid,
              }))
            }
            search={searchFormulas}
          />
        </div>
        {isMobile && <Select options={SORT_OPTIONS} value="name" />}
        <div className="flex gap-6 sm:gap-0 flex-col sm:flex-row w-full justify-between items-center">
          <div className="flex flex-wrap gap-4 justify-center flex-grow">
            {CHIPS.map((chip) => (
              <Chip key={chip.value} label={chip.label} value={chip.value} />
            ))}
          </div>
          {!isMobile && <span className="mr-auto sm:ml-auto">1000 groups</span>}
        </div>
        <div>
          {isMobile ? renderMobileTable() : renderDesktopTable()}

          <Pagination
            currentPage={1}
            totalResults={20}
            setCurrentPage={() => null}
          />
        </div>
      </div>
    </>
  );
};
