// Libraries
import React, { useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import jwtDecode from 'jwt-decode';
import { Navigate, useNavigate } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';
// Utils
import { useApi, CustomerAttributes, IApiData, IJWTPayload } from 'api';
import { UserContext } from 'context';
import { useAnalytics } from 'hooks';
// Constants
import * as AnalyticConstants from 'features/analytics/analytics.constants';
import { ROUTES } from 'features/navigation';
import { Button, Divider, Select, TextField, Typography } from 'design-system';

enum InputNames {
  givenName = 'givenName',
  familyName = 'familyName',
  companyName = 'companyName',
  addressLine1 = 'addressLine1',
  addressLine2 = 'addressLine2',
  city = 'city',
  state = 'state',
  zip = 'zip',
  email = 'email',
  phone = 'phone',
}

const validationSchema = yup.object({
  [InputNames.givenName]: yup
    .string()
    .strict()
    .required('First name is required')
    .max(256, 'First name cannot be longer than 256 characters')
    .trim('First name must not include a leading or trailing space'),
  [InputNames.familyName]: yup
    .string()
    .strict()
    .required('Last name is required')
    .max(256, 'Last name cannot be longer than 256 characters')
    .trim('Last name must not include a leading or trailing space'),
  [InputNames.companyName]: yup
    .string()
    .strict()
    .required('Company name is required')
    .max(256, 'Company name cannot be longer than 256 characters')
    .trim('Company name must not include a leading or trailing space'),
  [InputNames.email]: yup
    .string()
    .strict()
    .required('Email is required')
    .max(256, 'Company name cannot be longer than 256 characters')
    .trim('Company name must not include a leading or trailing space')
    .email('Invalid email'),
  [InputNames.addressLine1]: yup
    .string()
    .strict()
    .max(256, 'Address Line 1 cannot be longer than 256 characters')
    .trim('Address Line 1 must not include a leading or trailing space'),
  [InputNames.addressLine2]: yup
    .string()
    .strict()
    .max(256, 'Address Line 2 cannot be longer than 256 characters')
    .trim('Address Line 2 must not include a leading or trailing space'),
  [InputNames.city]: yup
    .string()
    .strict()
    .max(256, 'City cannot be longer than 256 characters')
    .trim('City must not include a leading or trailing space'),
  [InputNames.state]: yup
    .object({
      label: yup.string(),
      value: yup.string(),
    })
    .transform((_value, originalVal) =>
      typeof originalVal === 'object'
        ? originalVal
        : { label: originalVal, value: originalVal }
    ),
  [InputNames.zip]: yup
    .string()
    .strict()
    .max(256, 'Zip cannot be longer than 256 characters')
    .trim('Zip must not include a leading or trailing space'),
  [InputNames.phone]: yup
    .string()
    .strict()
    .max(256, 'Phone cannot be longer than 256 characters')
    .trim('Phone must not include a leading or trailing space'),
});

type CustomerFormValues = yup.InferType<typeof validationSchema>;

const INITIAL_FORM_VALUES: CustomerFormValues = {
  [InputNames.givenName]: '',
  [InputNames.familyName]: '',
  [InputNames.companyName]: '',
  [InputNames.addressLine1]: '',
  [InputNames.addressLine2]: '',
  [InputNames.city]: '',
  [InputNames.state]: { label: '', value: '' },
  [InputNames.zip]: '',
  [InputNames.email]: '',
  [InputNames.phone]: '',
};

export const STATES = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];

export const CustomerOnboarding: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const { setUserSession, setPermissions, userSession } = useContext(
    UserContext
  )!;
  const analytics = useAnalytics();
  const { postCustomer } = useApi();

  if (userSession.uuid) {
    return <Navigate to={ROUTES.HOME.route} />;
  }

  const handleSubmit = (
    values: CustomerFormValues,
    { setSubmitting }: FormikHelpers<CustomerFormValues>
  ) => {
    postCustomer({
      data: {
        customer: {
          thirdPartyId: user?.sub,
          ...values,
          state: values.state.value,
        },
      },
      handleSuccess: (
        { attributes, id }: IApiData<CustomerAttributes>,
        token: string
      ) => {
        analytics?.trackEvent({
          eventCategory: AnalyticConstants.EVENT_CATEGORIES.customerSignup,
          eventAction: AnalyticConstants.EVENT_ACTIONS.signup,
        });

        // Set the permissions first so that the Can component has the right permissions
        // before the customer gets set and triggers a change in authenticatedApp
        const decoded = jwtDecode<IJWTPayload>(token);
        if (decoded?.permissions?.length) {
          setPermissions(decoded.permissions);
        } else {
          // TODO: Handle this better
          throw new Error('No permissions found for user');
        }

        setUserSession({
          ...userSession,
          uuid: id || '',
          givenName: attributes.givenName,
          familyName: attributes.familyName,
          avatarColor: attributes.avatarColor,
          customerMetadata: {
            ...userSession.customerMetadata,
            companyName: attributes.companyName,
            companyUuid: attributes.companyUuid || '',
          },
        });
        amplitude.track('Customer Brief Started');
        navigate(ROUTES.CREATE_BRIEF.route);
      },
      handleFinally: () => setSubmitting(false),
    });
  };

  return (
    <Formik
      initialValues={{ ...INITIAL_FORM_VALUES }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ getFieldProps, errors, isSubmitting, submitForm, setFieldValue }) => (
        <div className="flex flex-col sm:grid sm:grid-cols-12 gap-x-6 mx-6 sm:mx-[60px] max-w-[1440px] pb-20">
          <div className="col-start-4 col-span-9">
            <div className="flex flex-col pt-[52px]">
              <div className="flex flex-col sm:grid sm:grid-cols-9 gap-12">
                <div
                  id="sample-recipients"
                  className="flex flex-col col-span-6 gap-y-9"
                >
                  <div>
                    <Typography variant="h2" font="agipo">
                      Create Your Account
                    </Typography>
                  </div>

                  {/* Start of form container */}
                  <div className="flex flex-col gap-6">
                    <div className="flex flex-col gap-3 w-full">
                      <div className="flex gap-9 justify-between">
                        <TextField
                          label="First Name"
                          required
                          {...getFieldProps(InputNames.givenName)}
                          disabled={isSubmitting}
                          id={'givenName'}
                          error={Boolean(errors.givenName)}
                          helperText={errors.givenName}
                        />
                        <TextField
                          label="Last Name"
                          required
                          {...getFieldProps(InputNames.familyName)}
                          disabled={isSubmitting}
                          id={'familyName'}
                          error={Boolean(errors.familyName)}
                          helperText={errors.familyName}
                        />
                      </div>
                      <div>
                        <TextField
                          label="Company name"
                          {...getFieldProps(InputNames.companyName)}
                          disabled={isSubmitting}
                          id={'companyName'}
                          error={Boolean(errors.companyName)}
                          helperText={errors.companyName}
                          required
                        />
                      </div>
                      <div>
                        <TextField
                          label="Address Line 1"
                          {...getFieldProps(InputNames.addressLine1)}
                          disabled={isSubmitting}
                          error={Boolean(errors.addressLine1)}
                          helperText={errors.addressLine1}
                        />
                      </div>
                      <div>
                        <TextField
                          label="Address Line 2"
                          {...getFieldProps(InputNames.addressLine2)}
                          disabled={isSubmitting}
                          error={Boolean(errors.addressLine2)}
                          helperText={errors.addressLine2}
                        />
                      </div>
                      <div className="flex gap-9 justify-between items-end">
                        <div>
                          <TextField
                            label="City"
                            {...getFieldProps(InputNames.city)}
                            disabled={isSubmitting}
                            error={Boolean(errors.city)}
                            helperText={errors.city}
                          />
                        </div>
                        <div className="flex-grow">
                          <Select
                            width="w-full"
                            placeholder="Select"
                            label="State"
                            options={STATES}
                            disabled={isSubmitting}
                            onChange={(option: Option) =>
                              setFieldValue(InputNames.state, option)
                            }
                          />
                        </div>
                        <div>
                          <TextField
                            label="Zip Code"
                            {...getFieldProps(InputNames.zip)}
                            disabled={isSubmitting}
                            error={Boolean(errors.zip)}
                            helperText={errors.zip}
                          />
                        </div>
                      </div>
                      <div className="flex gap-9 justify-between">
                        <TextField
                          label="Email"
                          required
                          {...getFieldProps(InputNames.email)}
                          disabled={isSubmitting}
                          error={Boolean(errors.email)}
                          helperText={errors.email}
                        />
                        <TextField
                          label="Phone Number"
                          {...getFieldProps(InputNames.phone)}
                          disabled={isSubmitting}
                          error={Boolean(errors.phone)}
                          helperText={errors.phone}
                        />
                      </div>
                    </div>
                  </div>
                  {/* End of form container */}
                  <div>
                    <Divider />
                  </div>
                  <div className="flex flex-row justify-end">
                    <Button
                      text="Submit"
                      variant="primary"
                      action={submitForm}
                      disabled={isSubmitting}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
