// Libraries
import React from 'react';
import { createPortal } from 'react-dom';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/16/solid';
import { ExclamationCircleIcon } from '@heroicons/react/16/solid';
import { ExclamationTriangleIcon } from '@heroicons/react/16/solid';
import { LightBulbIcon } from '@heroicons/react/24/outline';
import { SunIcon } from '@heroicons/react/24/outline';

export interface AlertProps {
  content: React.ReactNode | React.ReactNode[];
  headline?: string;
  onClose?: () => void;
  severity?: 'success' | 'error' | 'warning' | 'info' | 'tip';
  isBanner?: boolean;
}

const severityToColorAndIcon = {
  error: {
    bgColor: 'bg-red-95',
    icon: <ExclamationCircleIcon className="h-4 w-4 text-red-50" />,
    textColor: 'text-red-20',
    closeIconColor: 'text-red-50',
  },
  info: {
    bgColor: 'bg-royal-95',
    icon: <SunIcon className="h-4 w-4 text-royal-50" />,
    textColor: 'text-royal-20',
    closeIconColor: 'text-royal-50',
  },
  success: {
    bgColor: 'bg-green-95',
    icon: <CheckCircleIcon className="h-4 w-4 text-green-50" />,
    textColor: 'text-green-20',
    closeIconColor: 'text-green-50',
  },
  tip: {
    bgColor: 'bg-teal-95',
    icon: <LightBulbIcon className="h-4 w-4 text-teal-50" />,
    textColor: 'text-teal-20',
    closeIconColor: 'text-teal-50',
  },
  warning: {
    bgColor: 'bg-yellow-95',
    icon: <ExclamationTriangleIcon className="h-4 w-4 text-peach-60" />,
    textColor: 'text-peach-30',
    closeIconColor: 'text-peach-50',
  },
};

const AlertContent: React.FC<{
  icon: React.ReactNode;
  textColor: string;
  headline?: string;
  content: React.ReactNode | React.ReactNode[];
  onClose?: () => void;
  closeIconColor: string;
}> = ({ icon, textColor, headline, content, onClose, closeIconColor }) => (
  <div className={`flex justify-between items-center ${textColor}`}>
    {headline ? (
      <div className="flex flex-col">
        <div className="flex gap-2 items-center">
          {icon}
          <div className="font-inter text-sm font-semibold">{headline}</div>
        </div>
        <div className="font-inter text-sm px-6">{content}</div>
      </div>
    ) : (
      <div className="flex gap-2 items-center">
        {icon}
        <div className="font-inter text-sm">{content}</div>
      </div>
    )}
    {onClose && (
      <div className="cursor-pointer" onClick={onClose}>
        <XMarkIcon className={`h-4 w-4 ${closeIconColor}`} />
      </div>
    )}
  </div>
);

export const Alert: React.FC<AlertProps> = ({
  content,
  headline,
  onClose,
  isBanner,
  severity = 'info',
}) => {
  const { bgColor, closeIconColor, icon, textColor } = severityToColorAndIcon[
    severity
  ];

  const alertContent = (
    <AlertContent
      icon={icon}
      textColor={textColor}
      headline={headline}
      content={content}
      onClose={onClose}
      closeIconColor={closeIconColor}
    />
  );

  if (isBanner) {
    return (
      <div className={`${bgColor} flex flex-col rounded p-3 w-full mx-auto`}>
        {alertContent}
      </div>
    );
  }

  return createPortal(
    <div
      className={`${bgColor} flex flex-col gap-3 rounded p-3 w-full sm:w-[640px] fixed bottom-10 left-0 right-0 mx-auto z-[9999]`}
    >
      {alertContent}
    </div>,
    document.body
  );
};
