// Libraries
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'motion/react';
import { XMarkIcon } from '@heroicons/react/16/solid';
// Components
import { Button } from 'design-system';
import { ConfirmClearCartModal } from './confirm-clear-cart-modal.component';
// Utils
import { MobileContext, RtlShoppingCartContext, useAlert } from 'context';
import { useRenderCartItems } from './render-cart-items.hook';
// Constants
import { ROUTES } from 'features/navigation';

export const ShoppingCart: React.FC = () => {
  const navigate = useNavigate();
  const { isCartOpen, setIsCartOpen } = useContext(RtlShoppingCartContext);
  const { numberOfItemsInCart } = useContext(RtlShoppingCartContext);
  const { isMobile } = useContext(MobileContext);
  const [isClearCartModalOpen, setIsClearCartModalOpen] = useState(false);

  const cartItems = useRenderCartItems();

  const { showAlertWithTimeout } = useAlert();

  useEffect(() => {
    if (isCartOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isCartOpen]);

  const handleClose = () => {
    setIsClearCartModalOpen(false);
    setIsCartOpen(false);
    showAlertWithTimeout({
      content: 'Samples have been cleared from your cart.',
      severity: 'info',
    });
  };

  return (
    <>
      {isClearCartModalOpen && (
        <ConfirmClearCartModal handleClose={handleClose} />
      )}
      <AnimatePresence mode="wait">
        {isCartOpen && (
          <div className="w-full h-full fixed top-0 left-0 z-[1200] bg-black bg-opacity-25">
            <motion.div
              layout
              initial={{ right: `${isMobile ? '-80%' : '-475px'}` }}
              animate={{ right: '0px' }}
              exit={{ right: `${isMobile ? '-80%' : '-475px'}` }}
              transition={{ duration: 0.3, ease: 'linear' }}
              className={`flex flex-col fixed font-inter z-[1300] w-[80%] sm:w-[475px] h-full top-0 right-0 bg-grey-99 border border-grey-90`}
            >
              <div className="flex flex-col gap-4 p-6 overflow-y-auto no-scrollbar">
                <div
                  onClick={() => setIsCartOpen(false)}
                  className="absolute p-1 cursor-pointer top-3 right-3"
                >
                  <XMarkIcon className="h-5 w-5 text-grey-50" />
                </div>
                <span className="text-lg text-grey-20 font-semibold">
                  Samples Cart
                </span>
                <span className="text-grey-50 text-sm">
                  Formula samples to be shipped to a customer
                </span>
                <div className="flex flex-col gap-4 flex-1">
                  {cartItems}
                  <span
                    onClick={() => setIsClearCartModalOpen(true)}
                    className="text-grey-50 underline cursor-pointer uppercase font-mono text-xs"
                  >
                    Clear cart
                  </span>
                </div>
              </div>
              <div className="flex flex-col gap-4 p-6 border-t border-grey-20 mt-auto">
                <div className="flex justify-between items-center">
                  <span className="font-mono text-xs uppercase">
                    {numberOfItemsInCart} items total
                  </span>
                  {/* Hide until we have a customer view */}
                  {/* <span className="text-lg font-semibold">
                    {totalPriceOfItemsInCart}
                  </span> */}
                </div>
                <Button
                  disabled={numberOfItemsInCart < 1}
                  onClick={() => {
                    numberOfItemsInCart > 0 &&
                      navigate(
                        ROUTES.RTL_FORMULA_CATALOGUE_SHIPPING_DETAILS.route
                      );
                    setIsCartOpen(false);
                  }}
                  size="large"
                  type="primary"
                  text="Proceed to shipping..."
                  width="w-full"
                />
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </>
  );
};
